
@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-banner-login p {
  z-index: 100;
}

.bg-banner-login img {
  z-index: 100;
}

.bg-banner-login hr {
  z-index: 100;
}

.bg-banner-login:after {
  background: linear-gradient(190.41deg, rgb(8, 66, 39) -7.93%, rgb(243, 199, 5) 127.62%);
}

.bg-banner-login {
  position: relative;
}

.bg-banner-login::after {
  content: '';
  position: absolute;
  opacity: 0.1;
  display: block;
  left: 0;
  top:0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  -webkit-transition: background-color .3s ease-in-out;
  transition: background-color .3s ease-in-out;
  /* border: 5px solid #fff; */
  /* border-radius: 15px; */

}

.bg-banner-login .bg-light::after {
  background-color: #fff;
}

.root,
#root,
.MuiDialog-root,
#docs-root  {
  --color-primary: #004229;
  --color-secondary: #FF9600;
  --color-white: #fff;
  --color-gray: #c4c4c4;
  --color-light-gray: #E1E1E6; 
  --color-dark-gray: #41414D; 
  --color-opaque: #41414D;
  --color-green: #43b581;
  --color-blue: #0083C9;
  --color-red: #DD0329;
}